// plugins/system/src/routes.ts
import { createRouteRef } from '@backstage/core-plugin-api';

export const mySystemFormRouteRef = createRouteRef({
  id: 'system',
});

export const bookingsRouteRef = createRouteRef({
  id: 'system-bookings',
});

export const roomOccupancyRouteRef = createRouteRef({
  id: 'system-room-occupancy',
});

export const checkinsRouteRef = createRouteRef({
  id: 'system-checkins',
});

export const dailyBookingRouteRef = createRouteRef({
  id: 'system-daily-booking',
});

export const dailyCheckinsRouteRef = createRouteRef({
  id: 'system-daily-checkins',
});

export const nhdPerformanceRouteRef = createRouteRef({
  id: 'system-nhd-performance',
});

export const roomUtilisationRouteRef = createRouteRef({
  id: 'system-room-utilisation',
});
