import { createRouteRef, createExternalRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'home',
});

export const landingPageRouteRef = createRouteRef({
  id: 'landing',
});

export const systemRouteRef = createExternalRouteRef({
  id: 'system',
  optional: true,
});

// External routes to system plugin
export const bookingsRouteRef = createExternalRouteRef({
  id: 'bookings',
  optional: true,
});

export const roomOccupancyRouteRef = createExternalRouteRef({
  id: 'room-occupancy',
  optional: true,
});

export const checkinsRouteRef = createExternalRouteRef({
  id: 'checkins',
  optional: true,
});

export const dailyBookingRouteRef = createExternalRouteRef({
  id: 'daily-booking',
  optional: true,
});

export const dailyCheckinsRouteRef = createExternalRouteRef({
  id: 'daily-checkins',
  optional: true,
});

export const nhdPerformanceRouteRef = createExternalRouteRef({
  id: 'nhd-performance',
  optional: true,
});

export const roomUtilisationRouteRef = createExternalRouteRef({
  id: 'room-utilisation',
  optional: true,
});
