import React, { PropsWithChildren, useEffect } from 'react';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import BookIcon from '@material-ui/icons/Book';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import HotelIcon from '@material-ui/icons/Hotel';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { appThemeApiRef, useApi } from '@backstage/core-plugin-api';

interface SidebarItemWithTooltipProps {
  id: string;
  icon: any;
  to: string;
  text: string;
}

const useSidebarLogoStyles = makeStyles({
  root: {
    margin: '0 auto',
    width: '112px',
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link
        id="logo-home"
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const SidebarItemWithTooltip = ({ id, icon, to, text }: SidebarItemWithTooltipProps) => {

  const { isOpen } = useSidebarOpenState();

  return (
    <Tooltip title={isOpen ? '' : text} placement="right">
      <SidebarItem id={id} icon={icon} to={to}>
        <Typography
          variant="body1"
          style={{
            fontWeight: '500',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '150px',
            textAlign: 'left',
          }}
        >
          {text}
        </Typography>
      </SidebarItem>
    </Tooltip>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const themeApi = useApi(appThemeApiRef);

  useEffect(() => {
    const customThemeId = 'my-theme';
    const activeThemeId = themeApi.getActiveThemeId();

    if (!activeThemeId) {
      themeApi.setActiveThemeId(customThemeId);
    }
  }, [themeApi]);

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          <SidebarItemWithTooltip
            id="home"
            icon={HomeIcon}
            to="/"
            text="Home"
          />
          <SidebarItemWithTooltip
            id="bookings"
            icon={BookIcon}
            to="/bookings"
            text="Bookings"
          />
          <SidebarItemWithTooltip
            id="roomoccupancy"
            icon={MeetingRoomIcon}
            to="/roomoccupancy"
            text="Room Occupancy"
          />
          {/* <SidebarItemWithTooltip
            id="checkins"
            icon={CheckCircleIcon}
            to="/checkins"
            text="Checkings"
          />
          <SidebarItemWithTooltip
            id="roomutilisation"
            icon={EventSeatIcon}
            to="/roomutilisation"
            text="Room Utilisation"
          />
          <SidebarItemWithTooltip
            id="nhdperformance"
            icon={TrendingUpIcon}
            to="/nhdperformance"
            text="NHD Performance"
          />
          <SidebarItemWithTooltip
            id="dailybooking"
            icon={HotelIcon}
            to="/dailybooking"
            text="Daily Booking"
          />
          <SidebarItemWithTooltip
            id="dailycheckins"
            icon={AssignmentTurnedInIcon}
            to="/dailycheckins"
            text="Daily Checkins"
          /> */}
        </SidebarGroup>

        <SidebarDivider />
        <SidebarSpace />
        <SidebarDivider />

        <SidebarItemWithTooltip
          id="settings"
          icon={Settings}
          to="settings"
          text="Settings"
        />
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
