// plugins/home/plugin.ts
import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import {
  rootRouteRef,
  landingPageRouteRef,
  bookingsRouteRef,
  roomOccupancyRouteRef,
  checkinsRouteRef,
  dailyBookingRouteRef,
  dailyCheckinsRouteRef,
  nhdPerformanceRouteRef,
  roomUtilisationRouteRef,
} from './routes';

export const homePlugin = createPlugin({
  id: 'home',
  routes: {
    root: rootRouteRef,
  },
  externalRoutes: {
    bookings: bookingsRouteRef,
    roomOccupancy: roomOccupancyRouteRef,
    checkins: checkinsRouteRef,
    dailyBooking: dailyBookingRouteRef,
    dailyCheckins: dailyCheckinsRouteRef,
    nhdPerformance: nhdPerformanceRouteRef,
    roomUtilisation: roomUtilisationRouteRef,
  },
});

export const LandingPage = homePlugin.provide(
  createRoutableExtension({
    name: 'LandingPage',
    component: () =>
      import('./components/LandingPage').then(m => m.LandingPage),
    mountPoint: landingPageRouteRef,
  }),
);

/*
export const DashboardPage = homePlugin.provide(
  createRoutableExtension({
    name: 'DashboardPage',
    component: () =>
      import('./components/DashboardPage').then(m => m.DashboardPage),
    mountPoint: dashboardPageRouteRef,
  }),
);

export const DetailedArticlesPage = homePlugin.provide(
  createRoutableExtension({
    name: 'DetailedArticle',
    component: () =>
      import('./components/DetailedArticlesPage').then(m => m.DetailedArticles),
    mountPoint: detailedArticlePageRouteRef,
  }),
);

export const FAQsPage = homePlugin.provide(
  createRoutableExtension({
    name: 'FAQsPage',
    component: () => import('./components/FAQsPage').then(m => m.FAQsPage),
    mountPoint: faqPageRouteRef,
  }),
);

export const InformationPage = homePlugin.provide(
  createRoutableExtension({
    name: 'InformationPage',
    component: () =>
      import('./components/InformationPage').then(m => m.InformationPage),
    mountPoint: informationPageRouteRef,
  }),
);
*/
