// plugins/system/src/plugin.ts
import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import {
  mySystemFormRouteRef,
  bookingsRouteRef,
  roomOccupancyRouteRef,
  checkinsRouteRef,
  dailyBookingRouteRef,
  dailyCheckinsRouteRef,
  nhdPerformanceRouteRef,
  roomUtilisationRouteRef,
} from './routes';
import { MySystemApiClient, mySystemApiRef } from './api';

export const systemPlugin = createPlugin({
  id: 'system',
  routes: {
    root: mySystemFormRouteRef,
    bookings: bookingsRouteRef,
    roomOccupancy: roomOccupancyRouteRef,
    checkins: checkinsRouteRef,
    dailyBooking: dailyBookingRouteRef,
    dailyCheckins: dailyCheckinsRouteRef,
    nhdPerformance: nhdPerformanceRouteRef,
    roomUtilisation: roomUtilisationRouteRef,
  },
  apis: [
    createApiFactory({
      api: mySystemApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) =>
        new MySystemApiClient({ discoveryApi }),
    }),
  ],
});

export const BookingsPage = systemPlugin.provide(
  createRoutableExtension({
    name: 'BookingsPage',
    component: () => import('./components/BookingsPage').then(m => m.default),
    mountPoint: bookingsRouteRef,
  }),
);

export const CheckinsPage = systemPlugin.provide(
  createRoutableExtension({
    name: 'CheckinsPage',
    component: () => import('./components/CheckinsPage').then(m => m.default),
    mountPoint: checkinsRouteRef,
  }),
);

export const DailyBookingPage = systemPlugin.provide(
  createRoutableExtension({
    name: 'DailyBookingPage',
    component: () => import('./components/DailyBookingPage').then(m => m.default),
    mountPoint: dailyBookingRouteRef,
  }),
);

export const DailyCheckinsPage = systemPlugin.provide(
  createRoutableExtension({
    name: 'DailyCheckinsPage',
    component: () =>
      import('./components/DailyCheckinsPage').then(m => m.default),
    mountPoint: mySystemFormRouteRef,
  }),
);

export const NHDPerformancePage = systemPlugin.provide(
  createRoutableExtension({
    name: 'NHDPerformancePage',
    component: () =>
      import('./components/NHDPerformancePage').then(m => m.default),
    mountPoint: mySystemFormRouteRef,
  }),
);

export const RoomOccupancyPage = systemPlugin.provide(
  createRoutableExtension({
    name: 'RoomOccupancyPage',
    component: () =>
      import('./components/RoomOccupancyPage').then(m => m.default),
    mountPoint: mySystemFormRouteRef,
  }),
);

export const RoomUtilisationPage = systemPlugin.provide(
  createRoutableExtension({
    name: 'RoomUtilisationPage',
    component: () =>
      import('./components/RoomUtilisationPage').then(m => m.default),
    mountPoint: mySystemFormRouteRef,
  }),
);
