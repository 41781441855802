import { DiscoveryApi, createApiRef } from '@backstage/core-plugin-api';

export interface MySystemApi {
  saveSystem: (payload: any) => Promise<any[]>;
  getTeamGroups: ()=> Promise<any[]>;
  getBookings: ()=> Promise<any[]>;
  getCheckins: ()=> Promise<any[]>;
  getRoomutilisation: ()=> Promise<any[]>;
  getRoomoccupancy: ()=> Promise<any[]>;
}

export const mySystemApiRef = createApiRef<MySystemApi>({
  id: 'plugin.system.service',
});

export class MySystemApiClient implements MySystemApi {
  discoveryApi: DiscoveryApi;

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    // As configured previously for the backend proxy
    const proxyUri = `${await this.discoveryApi.getBaseUrl('system')}`;

    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) throw new Error(resp as any);
    return await resp.json();
  }

  async saveSystem(payload: any): Promise<any[]> {
    return await this.fetch<any[]>(
      '/system',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      },
    );
  }

  async getTeamGroups(): Promise<any[]> {
    return await this.fetch<any[]>(
      '/teamgroups',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }

  async getBookings(): Promise<any[]> {
    return await this.fetch<any[]>(
      '/reports/bookings',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }

  async getCheckins(): Promise<any[]> {
    return await this.fetch<any[]>(
      '/reports/checkins',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }

  async getRoomutilisation(): Promise<any[]> {
    return await this.fetch<any[]>(
      '/reports/roomutilisation',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }

  async getRoomoccupancy(): Promise<any[]> {
    return await this.fetch<any[]>(
      '/reports/roomoccupancy',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }

  async getDailyCheckin(): Promise<any[]> {
    return await this.fetch<any[]>(
      '/reports/dailycheckin',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }
}
